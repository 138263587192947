<template>
  <b-container fluid>
    <b-card title="Firma Kaydı">
      <hr/>
      <b-row>
        <b-col cols="4">
          <b-form-group
              label="Kurum Tam Ünvan"
              label-for="basicInput"
          >
            <b-form-input
                v-model="tamUnvan"
                id="basicInput" autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Kurum Kısa Ünvan"
              label-for="basicInput"
          >
            <b-form-input
                v-model="unvan"
                id="basicInput" autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="İl"
              label-for="basicInput"
          >
            <b-form-input
                v-model="il"
                id="basicInput" autocomplete="off"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="İlçe"
              label-for="basicInput"
          >
            <b-form-input
                v-model="ilce"
                id="basicInput" autocomplete="off"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Telefon"
              label-for="basicInput"
          >
            <b-form-input
                v-model="telefon"
                id="basicInput" autocomplete="off"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="E-Posta"
              label-for="basicInput"
          >
            <b-form-input
                v-model="ePosta"
                id="basicInput" autocomplete="off"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Kurum Yetkilisi"
              label-for="basicInput"
          >
            <b-form-input
                v-model="kurumYetkilisi"
                id="basicInput" autocomplete="off"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Kurun Yetkilisi Telefon"
              label-for="basicInput"
          >
            <b-form-input
                v-model="yetkiliTelefon"
                id="basicInput" autocomplete="off"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Adres"
              label-for="basicInput"
          >
            <b-form-input
                v-model="adres"
                id="basicInput" autocomplete="off"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Web Site Adresi"
              label-for="basicInput"
          >
            <b-form-input
                v-model="siteURL"
                id="basicInput" autocomplete="off"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Arvas Api KEY"
              label-for="basicInput"
          >
            <b-form-input
                v-model="apiKey"
                id="basicInput" autocomplete="off"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="CDN"
              label-for="basicInput"
          >
            <b-form-input
                v-model="CDN"
                id="basicInput" autocomplete="off"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Firma Kodu"
              label-for="basicInput"
          >
            <b-form-input
                v-model="saveFirmaKodu"
                id="basicInput" autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Yönetici Kullanıcı Adı"
              label-for="basicInput"
          >
            <b-form-input
                v-model="user"
                id="basicInput" autocomplete="off"

            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Yönetici Şifresi"
              label-for="basicInput"
          >
            <b-form-input v-model="pwd" id="basicInput" autocomplete="off"/>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="ml-1">
          <b-card-text class="mb-0">
            Create Host
          </b-card-text>
          <b-form-checkbox
              v-model="createHost"
              class="custom-control-primary"
              name="check-button"
              switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon"/>
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon"/>
            </span>
          </b-form-checkbox>
          <hr>
        </b-col>
        <b-col v-if="createHost" cols="4">
          <b-form-group
              label="Domain"
              label-for="basicInput"
          >
            <b-form-input id="basicInput" v-model="domain" autocomplete="off"/>
          </b-form-group>
        </b-col>
        <b-col v-if="createHost" cols="4">
          <b-form-group
              label="Host User"
              label-for="basicInput"
          >
            <b-form-input id="basicInput" v-model="userName" autocomplete="off"/>
          </b-form-group>
        </b-col>
        <b-col v-if="createHost" cols="4">
          <b-form-group
              label="User Pass"
              label-for="basicInput"
          >
            <b-form-input id="basicInput" v-model="userPass" autocomplete="off"/>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button @click="kurumSave" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
            <feather-icon
                icon="SaveIcon"
                class="mr-50"
            />
            <span class="align-middle">Kaydet</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import {
  BCardText,
  BFormCheckbox,
  BButton,
  BFormGroup,
  BInputGroup,
  BCard,
  BRow,
  BCol,
  BContainer,
  BNavbarNav,
  BNavItem,
  BTooltip,
  BNavItemDropdown,
  BFormInput,
  BDropdownItem,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardText,
    BFormCheckbox,
    BButton,
    BFormGroup,
    BInputGroup,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BFormSelectOption,
    vSelect,
    BNavbarNav,
    BNavItem,
    BTooltip,
    BNavItemDropdown,
    BFormInput,
    VuePerfectScrollbar,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      createHost: false,
      domain: '',
      userName: '',
      userPass: '',
      tamUnvan: '',
      unvan: '',
      il: '',
      ilce: '',
      telefon: '',
      ePosta: '',
      kurumYetkilisi: '',
      yetkiliTelefon: '',
      adres: '',
      siteURL: '',
      apiKey: '',
      CDN: '',
      saveFirmaKodu: '',
      user: '',
      pwd: '',
    }
  },
  computed: {},
  methods: {
    kurumSave() {
      store.dispatch('kurumSave', {
        tamUnvan: this.tamUnvan,
        unvan: this.unvan,
        il: this.il,
        ilce: this.ilce,
        telefon: this.telefon,
        ePosta: this.ePosta,
        kurumYetkilisi: this.kurumYetkilisi,
        yetkiliTelefon: this.yetkiliTelefon,
        adres: this.adres,
        siteURL: this.siteURL,
        apiKey: this.apiKey,
        CDN: this.CDN,
        saveFirmaKodu: this.saveFirmaKodu,
        user: this.user,
        pwd: this.pwd,
        createHost: this.createHost,
        domain: this.domain,
        userName: this.userName,
        userPass: this.userPass,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status === 200) {
              this.$router.push('/')
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.custom-select {
  width: 150% !important;
  background-color: #65c18c !important;
  color: white !important;
  border-color: transparent;
}

.custom-select option {
  width: 150% !important;
  background-color: white !important;
  color: black !important;
}

.default-select {
  color: black !important;
}
</style>
